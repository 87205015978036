import styled from 'styled-components';

const SearchResults = (props) => {
    return (
        <Container>
            { props.children }
        </Container>
    )
}

export default SearchResults

const Container = styled.div`
    max-width: 360px;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    @media (max-width: 960px) {
        max-width: 100%;
        max-height: 40vh;
        order: 2;
    }
`