import React from 'react'
import styled from 'styled-components'
import heroBg from '../../assets/images/hero-bg.jpg'

const HeroState = (props) => {

    return (
        <Container>
            <Background src={heroBg} />
            <PreTitle>Fundraise & Recycle</PreTitle>
            <Title>Where fundraising & innovative textile recycling meet</Title>
            {props.searchFormElement}
        </Container>
    )
}

export default HeroState

const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(232, 248, 255, 0.8);

    .search-form-container {
        width: 100%;
        margin-top: 20px;
    }

    .search-form {
        position: static;
        left: auto;
        top: auto;
        width: 100%;
    }
`

const Background = styled.img`
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
`

const PreTitle = styled.p`
    font-size: 24px;
    font-weight: 500;
    line-height: 31.88px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: black;
    margin-top: 0;
`

const Title = styled.h1`
    font-size: 80px;
    font-weight: 700;
    line-height: 96px;
    letter-spacing: -0.02em;
    text-align: center;
    text-decoration-skip-ink: none;
    max-width: 900px;
    margin: 0;

    @media (max-width: 550px) {
        font-size: 40px;
        line-height: 56px;
    }
`