import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SharePopup from '../components/SharePopup';
import { db } from '../util/firebaseApp'
import { doc, setDoc, collection } from 'firebase/firestore'
import axios from 'axios';

const BookCollection = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [formStatus, setFormStatus] = useState('Send');
    const form = useRef();
    const [bank, setBank] = useState();
    const [sharePopupActive, setSharePopup] = useState(false);
    const [searchParams] = useSearchParams();
    const [bankId, setBankId] = useState("");
    const [shareActive, setShareState] = useState(false);
    const [campaignId, setCampaignId] = useState("");

    useEffect(() => {
        setBankId(searchParams.get('bankId') ? searchParams.get('bankId') : "yewkids-nursery");
    }, []);

    useEffect(() => {
        getBankInfo();
    }, [bankId]);

    const getBankInfo = async () => {
        console.log("Getting Bank Info")
        if (!bank) {
            axios
                .get(`${apiUrl}/bank/${bankId}`)
                .then((res) => {
                    console.log("Found Bank: " + res.data.name)
                    setBank(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { name, email, phone, address, date } = e.target.elements;
        let collectionForm = {
            name: name.value,
            email: email.value,
            phone: phone.value, 
            bankId: bankId,
            address: address.value,
            date: date.value
        }
        console.log(collectionForm)

        const newCollection = doc(collection(db, "collectionCampaigns"));
        await setDoc(newCollection, collectionForm);

        axios
            .post(`${apiUrl}/collectionrequest/submit-request`, collectionForm)
            .then((response) => {
                console.log(response);
                setCampaignId(response.data.request_id)
                setShareState(true)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error)
                }
                console.log(error)
                alert("Submission failed, please try again");
            })
    }

    return (
        <PageContainer>
            {
                sharePopupActive ?
                <SharePopup exitFunction={setSharePopup} /> :
                ""
            }
            <Header />
            <PageContent>
                <Title>Start a fundraising campaign by organising a group collection</Title>
                <Subtitle>Share to minimum of 10 people to join your fundraising campaign for {bank ? bank.name : "Fundraise & Recycle"}.</Subtitle>

                {
                !shareActive ?
                <Form ref={form} onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" name="name" type="text" id="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email Address</label>
                        <input className="form-control" name="email" type="text" id="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="phone">Contact Number</label>
                        <input className="form-control" name="phone" type="text" id="phone" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="address">Collection Address</label>
                        <textarea className="form-control" name="address" id="address" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="date">Collection Date</label>
                        <input className="form-control" name="date" type="date" id="date" />
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form> :
                <ShareButton className="cta-3" onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: `I choose you to help me and join my Fundraise and Recycle campaign by recycling and clearing out your wardrobe to help fundriase for ${bank.name}.`,
                            url: `/bank?bankId=${bankId}&campaignid=${campaignId}`,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    } else {
                        setSharePopup(true);
                    }
                }}>
                    <p>SHARE MY CAMPAIGN!</p>
                </ShareButton>
                }
            </PageContent>
            <Footer />
        </PageContainer>
    )
}

export default BookCollection

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1060px) {
        background: #F8FDFF
    }
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    min-height: 68vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
    margin: 0;
`

const Subtitle = styled.h2`
    padding-left: 18px;
`

const Paragraph = styled.p`

`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 0 24px 24px 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input,
    textarea,
    select {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        margin-top: 24px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`

const ShareButton = styled.a`
    margin-left: 18px;
    background: #BE3258;
    color: white;

    border-radius: 50px;

    p {
        color: white;
        padding: 0 20px;
        font-weight: bold;
    }

    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`