import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from 'react-router-dom';

const BehindBars = (props) => {
    const [bankId, setBankId] = useState("u6UdNFZfueGHHBcG0a2C");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setBankId(searchParams.get('bankId'));
        document.title = "Bank Behind Bars";
    }, []);

    return (
        <PageContainer>
            <a href={`http://online.fundraiserecycleltd.co.uk/bank?bankId=${bankId}`} >
                <BehindBarsAnimation src="https://firebasestorage.googleapis.com/v0/b/fundraise-recycle-app.appspot.com/o/Bank%20Behind%20Bars.gif?alt=media&token=0402c885-efa3-4d19-8b8e-9fbea21a6be3" />
            </a>
        </PageContainer>
    )
}

export default BehindBars

const BehindBarsAnimation = styled.img`
    max-width: 100%;
    max-height: 100%;
`

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    overflow: hidden;
`