export const menuItems = [{
	"id": "home",
	"label": "Home",
	"link": "/",
	"active": false
}, {
	"id": "about",
	"label": "About us",
	"link": "https://www.fundraiserecycle.com/about-us",
	"active": false
}, {
	"id": "contact",
	"label": "Contact",
	"link": "https://www.fundraiserecycle.com/contact",
	"active": false
}];