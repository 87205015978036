import React, { useState } from 'react'
import styled from 'styled-components'

import headerImage from '../assets/images/fr_logo.svg'
import { menuItems } from '../assets/data/menu'

const Header = (props) => {
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const setMobileMenu = (state) => {
        setMobileMenuActive(state);
        console.log("Mobile Menu State: " + mobileMenuActive);

    }

    return (
        <Container className={props.isHome ? "home-header" : ""}>  
            
            { 
            mobileMenuActive ?
            <MobileMenu>
                <CloseButton onClick={() => {setMobileMenu(false)}}>X</CloseButton>
                <div>
                    {menuItems.map((item) => (
                        <MobileMenuItem>
                            <a key={item["label"]} href={item["link"]}>{item["label"]}</a>
                        </MobileMenuItem>
                    ))}
                </div>
            </MobileMenu> :
            "" 
            }

            <HeaderLeft>
                <HeaderImageContainer src={headerImage} />
            </HeaderLeft>
            <HeaderRight>
            <MenuRow>
                <MenuContainer>
                    {menuItems.map((menuItem) => {
                        return (
                            <MenuItem key={menuItem["label"]} href={menuItem["link"]}>{menuItem["label"]}</MenuItem>
                        )
                    })}
                </MenuContainer>
            </MenuRow>
            </HeaderRight>
            <BurgerButton onClick={() => {setMobileMenu(true)}}>
                <svg viewBox="0 0 100 80" width="36" height="36">
                    <rect width="100" height="20"></rect>
                    <rect y="30" width="100" height="20"></rect>
                    <rect y="60" width="100" height="20"></rect>
                </svg>  
            </BurgerButton>
        </Container>
    )
}

export default Header

const Container = styled.div`
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    padding: 24px 0;
    z-index: 5;

    @media (max-width: 1060px) {
        justify-content: center;
    }

    @media (max-width: 480px) {
        flex-direction: column;
    }
`

const MenuItem = styled.a`
    font-family: 'ibm-plex-sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    text-decoration: none;

    margin: 0 12px;
    padding: 4px 0;

    :hover {
        text-decoration: underline;
        border: 1px solid #003955;
        border-width: 1px 0 1px 0;
    }
`

const MenuRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0 48px 0;

    @media (max-width: 1060px) {
        justify-content: center;
    }
`

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 24px;

    @media (max-width: 720px) {
        margin: 0 auto;
    }

    @media (max-width: 540px) {
        flex-flow: row wrap;
        align-items: center;

        a {
            margin-top: 12px;
        }
    }

    @media (max-width: 1060px) {
        display: none;
    }
`

const HeaderImageContainer = styled.img`
    width: auto;
    height: auto;
    background: #F8FDFF;
    border-radius: 24px;
`

const HeaderLeft = styled.div`
    padding: 0 24px;

    @media (max-width: 450px) {
        padding: 0;
        margin-top: 12px;
    }
`

const HeaderRight = styled.div`
    @media (max-width: 1060px) {
        display: none;
    }
`

const BurgerButton = styled.a`
    position: absolute;
    top: 33px;
    left: 40px;
    border: 0;
    background: none;

    rect {
        fill: white;
        height: 13px;
    }

    @media (min-width: 1060px) {
        display: none;
    }

    @media (max-width: 580px) {
        top: 15px;
    }
`

const MobileMenu = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background: #ecb525;
    z-index: 6;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;

    @media (min-width: 1060px) {
        display: none;
    }
`

const CloseButton = styled.a`
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    color: white;
    cursor: default;
`

const MobileMenuItem = styled.div`
    padding: 12px 0;
    border: 1px solid white;
    border-left: 0;
    border-right: 0;
    
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 24px;
    }
`