import React, { useMemo } from 'react'
import styled from 'styled-components'
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api"

const Map = (props) => {
    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: "AIzaSyAB6flGdVD7hrgXPtwoNZSI87NeBRpbfps" });
    const zoom = useMemo(() => (10), []);

    return (
    <Container>
    {
        isLoaded ? (
        <GoogleMap
          ref={(map) => console.log()}
          center={props.center}
          zoom={zoom}
          mapContainerClassName='map-container'
          fullscreenControl={false}
        >
          <>
            {props.children}
          </>
        </GoogleMap>
    ) : <p>Failed to load</p>
    }
    </Container>
    )
}

export default Map

const Container = styled.div`
    
    width: 100%;
    height: 100%;

    .map-container {
        width: 100%;
        height: 100%;
    }
`