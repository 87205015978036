import styled from 'styled-components'

export const PageContainer = styled.div`
    max-width: 1690px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F8FDFF;
`

export const HeroSection = styled.div`
    position: relative;
    width: 100%;
    z-index: 0;
    background: white;
    border: 4px solid #EDB524;
    border-radius: 32px;

    ::after {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 30%;
        z-index: -1;
        content: "";
        background: #EDB524;
    }

    @media (max-width: 1060px) {
        ::after {
            background: white;
        }
        border: 0;
    }
`

export const VideoStatSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 100px 0;

    @media (max-width: 1090px) {
        gap: 20px;
    }

    @media (max-width: 1060px) {
        flex-direction: column;
        padding-bottom: 0;
        padding-top: 20px;
        background: #F8FDFF;
    }
`

export const VideoContainer = styled.div`
    width: 40vw;
    overflow: hidden;

    figure {
        margin: 0;
    }

    @media (max-width: 1360px) {
        width: 50vw;
    }

    video {
        width: 100%;
        border-radius: 16px;
    }

    @media (max-width: 1060px) {
        width: 80vw;
    }
`

export const StatContainer = styled.div`
`

export const Stats = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 18px;

    span {
        font-family: "futura-pt";
        font-size: 32px;
        font-weight: 700;
        line-height: 42.53px;
        text-align: center;
        text-decoration-skip-ink: none;
        color: white;
    }

    p {
        color: white;
        padding: 10px 20px;
        text-align: center;
        font-family: "futura-pt";
        font-size: 16px;
        font-weight: 700;
        line-height: 21.26px;
        text-decoration-skip-ink: none;
    }

    @media (max-width: 760px) {
        span {
            font-size: 22px;
            line-height: 32.53px;
        }
    }

    @media (max-width: 500px) {
        column-gap: 8px;
        p {
            font-size: 14px;
        }
        span {
            font-size: 16px;
        }
    }
`

export const QuantityStat = styled.p`
    border-radius: 60px 0 0 60px;
    background: #EDB524;
`

export const RaisedStat = styled.p`
    background: #BE3258;
`

export const TreesStat = styled.p`
    background: #174B64;
    color: white;
    border-radius: 0 60px 60px 0;

    a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        line-height: 2;
    }
`

export const HeroContent = styled.div`
`

export const Title = styled.h1`
    font-weight: 700;
    line-height: 1;
    font-size: 32px;
    max-width: 280px;
    color: #003955;
    margin: 0;
    line-height: 38.4px;
    letter-spacing: -0.02em;
    text-align: left;
    text-decoration-skip-ink: none;


    @media (max-width: 1060px) {
        text-align: center;
        max-width: none;
        margin-bottom: 24px;
    }

    @media (max-width: 684px) {
        max-width: 100%;
    }
`

export const GoalText = styled.p`
    color: #003955;
    max-width: 444px;
    margin: 0;
    font-family: "ibm-plex-sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    text-align: left;
    text-decoration-skip-ink: none;


    @media (max-width: 1060px) {
        text-align: center;
        max-width: 80vw;
        margin: 0 auto;
    }
`

export const CTAStrip = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 18px;
    width: 100%;
    margin: 0 auto;
    margin: 48px 0;

    @media (max-width: 960px) {
        column-gap: 8px;
    }
`

export const CTAContainer = styled.div`
    background: #BE3258;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
    position: relative;
    height: 180px;
    cursor: pointer;

    @media (max-width: 720px) {
        height: 120px;
    }
`

export const CTAIcon = styled.img`
    margin: 0 auto;
    width: 64px;
    height: auto;
    fill: white;

    @media (max-width: 680px) {
        width: 48px;
        height: auto;
    }
`

export const CTAText = styled.p`
    font-size: 28px;
    color: white;
    text-align: center;
    margin: 12px 0 0 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 21.25px;
    text-align: center;
    text-decoration-skip-ink: none;

    @media (max-width: 720px) {
        font-size: 12px;
        line-height: 16px;
    }
`

export const AdvertStrip = styled.div`
    width: 100%;
`

export const AdvertCard = styled.a`
    box-shadow: 6.04px 6.04px 30.22px 0px rgba(0, 0, 0, 0.1) inset;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-image: url(${props => (props.backgroundimage)});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 24px;

    p {
        color: rgb(0, 57, 85);
        font-size: 2.5vw;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
    }

    img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        object-fit: cover;
        object-position: center;
        z-index: -1;
    }

    @media (max-width: 1060px) {
        width: 22vw;
        height: 14vw;
    }

    @media (max-width: 920px) {
        border-radius: 16px;
        width: 24vw;
    }
`

export const MapStrip = styled.div`
    width: 100%;
    height: 450px;
    overflow: hidden;

    position: relative;
    border-radius: 16px;

    iframe {
        border: 0;
    }
`

export const ExitPopup = styled.a`
    color: white;
    font-size: 36px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 101;
    cursor: pointer;
`

export const MapInfoContent = styled.div`
    margin: auto;
    border-radius: 60px;
    box-shadow: 0px 0px 26px -1px rgba(0,0,0,0.75);
    background: white;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;

    h2 {
        text-align: center;
    }

    .border-above {
        border-top: 3px solid #003955;
    }

    h3 {
        position: relative;
        text-align: center;
        margin: 0;
        padding-top: 8px;
    }

    p {
        text-align: center;
    }
`

export const ShareCount = styled.p`
    font-family: 'ibm-plex-sans';
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    letter-spacing: -0.01em;

    text-align: center;
    font-size: 30px;
    color: white;
    margin: 0;
    color: #033A57;
`

export const MapInfoBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
`

export const SponsorStrip = styled.h2`
    font-size: 48px;
    font-weight: 700;
    line-height: 55.2px;
    letter-spacing: -0.02em;
    margin-top: 0;
    text-decoration-skip-ink: none;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #033A57;
`

export const AdvertCardContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2ch;
    margin: 48px auto;
    grid-auto-rows: 1fr;

    @media (max-width: 920px) {
        gap: 1ch;
    }
`