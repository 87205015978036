import react, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db, storage } from '../../util/firebaseApp';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore'

const PopupContent = ({bank}) => {
    const [isVideo, setIsVideo] = useState(false);
    const [bankImg, setImage] = useState("");
    const [sponsors, setSponsors] = useState({});
    const [defaultSponsors, setDefaultSponsors] = useState([]);
    const [co2save, setCo2Save] = useState(0.0);

    const fetchBankPhoto = async () => {
        if (bank.image) {
            if (bank.image.slice(-1) === "4") {
                setIsVideo(true);
            }
            setImage("https://api.fundraiserecycle.com/media/" + bank.image)
        } else {
            setIsVideo(true);
            setImage("https://api.fundraiserecycle.com/media/bank-images/Jake%20Tutorial%20Reduced.mp4#t=0.1")
        }
    }

    const setSponsorImage = async (fileToGet, idToSet) => {
        if (fileToGet && idToSet) {
            document.getElementById(idToSet).src = ("https://api.fundraiserecycle.com/media/" + fileToGet);
        }
    }

    const fetchSponsors = async () => {
        var sponsorsTemp = [];

        fetchSponsorData(sponsorsTemp)
            .then(() => {
                if (sponsorsTemp.length > 0) {
                    setSponsors(sponsorsTemp);
                } else {
                    setSponsors(defaultSponsors);
                }
            })
    }

    const setupDefaultSponsors = async () => {
        let temp = [];
        const querySnapshot = await getDocs(collection(db, "default-sponsors"));
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setDefaultSponsors(temp);
    }

    const fetchSponsorData = async (sponsorArray) => {
        let sponsor1 = {};
        let sponsor2 = {};
        let sponsor3 = {};
        let sponsor4 = {};
        let sponsor5 = {};
        let sponsor6 = {};
        let sponsor7 = {};
        let sponsor8 = {};

        if (bank.sponsor1name) {
            sponsor1["title"] = bank.sponsor1name;
            sponsor1["link"] = bank.sponsor1link;
            sponsor1["image"] = bank.sponsor1image; 
            sponsor1["text"] = bank.sponsor1text;
            sponsor1["id"] = "sponsor-1";
            setSponsorImage(bank.sponsor1image, "sponsor-1");

            sponsorArray.push(sponsor1);
        }

        if (bank.sponsor2name) {
            sponsor2["title"] = bank.sponsor2name;
            sponsor2["link"] = bank.sponsor2link;
            sponsor2["image"] = bank.sponsor2image; 
            sponsor2["text"] = bank.sponsor2text;
            sponsor2["id"] = "sponsor-2";
            setSponsorImage(bank.sponsor2image, "sponsor-2");

            sponsorArray.push(sponsor2);
        }

        if (bank.sponsor3name) {
            sponsor3["title"] = bank.sponsor3name;
            sponsor3["link"] = bank.sponsor3link;
            sponsor3["image"] = bank.sponsor3image; 
            sponsor3["text"] = bank.sponsor3text;
            sponsor3["id"] = "sponsor-3";
            setSponsorImage(bank.sponsor3image, "sponsor-3");
            
            sponsorArray.push(sponsor3);
        }

        if (bank.sponsor4name) {
            sponsor4["title"] = bank.sponsor4name;
            sponsor4["link"] = bank.sponsor4link;
            sponsor4["image"] = bank.sponsor4image; 
            sponsor4["text"] = bank.sponsor4text;
            sponsor4["id"] = "sponsor-4";
            setSponsorImage(bank.sponsor4image, "sponsor-4");

            sponsorArray.push(sponsor4);
        }

        if (bank.sponsor5name) {
            sponsor5["title"] = bank.sponsor5name;
            sponsor5["link"] = bank.sponsor5link;
            sponsor5["image"] = bank.sponsor5image; 
            sponsor5["text"] = bank.sponsor5text;
            sponsor5["id"] = "sponsor-5";
            setSponsorImage(bank.sponsor5image, "sponsor-5");

            sponsorArray.push(sponsor5);
        }

        if (bank.sponsor6name) {
            sponsor6["title"] = bank.sponsor6name;
            sponsor6["link"] = bank.sponsor6link;
            sponsor6["image"] = bank.sponsor6image; 
            sponsor6["text"] = bank.sponsor6text;
            sponsor6["id"] = "sponsor-6";
            setSponsorImage(bank.sponsor6image, "sponsor-6");

            sponsorArray.push(sponsor6);
        }

        if (bank.sponsor7name) {
            sponsor7["title"] = bank.sponsor7name;
            sponsor7["link"] = bank.sponsor7link;
            sponsor7["image"] = bank.sponsor7image; 
            sponsor7["text"] = bank.sponsor7text;
            sponsor7["id"] = "sponsor-7";
            setSponsorImage(bank.sponsor7image, "sponsor-7");

            sponsorArray.push(sponsor7);
        }

        if (bank.sponsor8name) {
            sponsor8["title"] = bank.sponsor8name;
            sponsor8["link"] = bank.sponsor8link;
            sponsor8["image"] = bank.sponsor8image; 
            sponsor8["text"] = bank.sponsor8text;
            sponsor8["id"] = "sponsor-8";
            setSponsorImage(bank.sponsor8image, "sponsor-8");
            
            sponsorArray.push(sponsor8);
        }
    }

    useEffect(() => {
        fetchBankPhoto();
        setupDefaultSponsors();
        fetchSponsors();
        setCo2Save((parseFloat(bank.quantityRecycled.split(',').join(""))) * 3.6)
    })

    return (
        <Container>
            <Header>
                <VideoContainer>
                    {
                        bankImg ?
                            isVideo ?
                            <figure><video preload="metadata" controls src={`${bankImg}#t=0.1`}></video></figure> :
                            <img src={bankImg} alt="" /> :
                        <figure><video preload="metadata" controls src={`https://fundraise-recycle.s3.us-west-002.backblazeb2.com/fr-bank-default.mp4#t=0.1`}></video></figure>
                    }
                </VideoContainer>
            </Header>
            <Title target="_blank" href={`/bank?bankId=${bank.bankId}`}>{bank.name}</Title>
            <StatsContainer>
                <p><strong>Total Recycled:</strong> {bank.quantityRecycled}kg</p>
                <p><strong>Total Raised:</strong> £{bank.moneyRaised}</p>
                <p><strong>Minimum CO2 Saved:</strong> {co2save.toFixed(0)}kg</p>
            </StatsContainer>
            <AdContainer>
                { sponsors.length > 0 ? sponsors.map((tile) => {
                    if (tile.image) {
                        return (
                            <Advert key={tile["title"]} href={tile["link"] ? tile["link"] : "#"} target={"_blank"}>
                                <img id={tile.id} src={`https://api.fundraiserecycle.com/media/${tile.image}`} /> 
                            </Advert>
                        );
                    }
                }) : ""}
            </AdContainer>
            <Footer>

            </Footer>
        </Container>
    )
}

export default PopupContent

const Container = styled.div`
    margin-top: 28px;
    max-width: 340px;
`

const Title = styled.a`
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin-top: 12px;
    width: 100%;
    text-decoration: none;
    text-align: center;

    :hover {
        text-decoration: underline;
    }
`

const StatsContainer = styled.div`
    margin-bottom: 18px;
    p {
        margin: 8px 0;
    }
`

const Header = styled.div`

`

const Footer = styled.div`

`

const AdContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    width: 100%;
    //row-gap: 1ch;
    //column-gap: 1ch;
    overflow: visible;
    //padding: 3px;
`

const Advert = styled.a`
    width: 100%;
    height: 100%;

    background-position: center;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    img {
        width: 100%;
        height: auto;
        line-height: 0;
        margin: auto;
        object-fit: cover;
        object-position: center;
    }
`

const VideoContainer = styled.div`
    width: 100%;
    overflow: hidden;

    figure {
        margin: 0;
    }

    video {
        width: 100%;
    }
`