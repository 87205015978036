import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const SponsorPopup = ({ exitFunction, bankId, bankName }) => {
    const [tcUrl, setTCUrl] = useState('');
    const [oneTileSelected, setOneTileSelected] = useState(false);
    const [campaignSelected, setCampaignSelected] = useState(false);
    const [tcAccepted, setAcceptance] = useState(false);

    useEffect(() => {
        setTCUrl("https://api.fundraiserecycle.com/media/T&C.pdf")
    }, [])

    return (
        <Container>
            <ShareContainer>
                <CloseButton onClick={() => {exitFunction(false)}}>X</CloseButton>
                {
                    oneTileSelected && campaignSelected ?
                    <>
                        <SponsorButton onClick={() => {setOneTileSelected(true)}}>Buy this tile</SponsorButton>
                        <SponsorButton onClick={() => {setCampaignSelected(true)}}>Start an advertising campaign</SponsorButton>
                    </> : ""
                }
                {
                    !oneTileSelected && !campaignSelected ?
                    <>
                        <div className='sponsor-check'>
                            <label htmlFor="onetc">I Accept the <a href={tcUrl}>Terms and Conditions</a></label>
                            <input id="onetc" onClick={() => {setAcceptance(!tcAccepted)}} type="checkbox" />
                        </div>
                        {
                            !tcAccepted ?
                            <DisabledButton>Continue</DisabledButton> :
                            <SponsorButton href={`/sponsor?bankId=${bankId}`}>Continue</SponsorButton>
                        }
                    </> : ""
                }
                {
                    campaignSelected && oneTileSelected ?
                    <>
                        <div className='sponsor-check'>
                            <label htmlFor="onetc">I Accept the <a href="/sponsor-tc-campaign">Terms and Conditions</a></label>
                            <input id="onetc" onClick={() => {setAcceptance(!tcAccepted)}} type="checkbox" />
                        </div>
                        {
                            !tcAccepted ?
                            <DisabledButton>Continue</DisabledButton> :
                            <SponsorButton href="/sponsor-campaign">Continue</SponsorButton>
                        }
                    </> : ""
                }
            </ShareContainer>
        </Container>
    )
}

export default SponsorPopup;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .sponsor-check input {
        margin-left: 8px;
    }
`

const ShareContainer = styled.div`
    background: white;
    position: relative;
    margin: auto;
    border-radius: 18px;
    padding: 18px;
    display: flex;
    flex-direction: column;

    h2 {
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 0;
    }

    input {
        margin: 4px 0;
        width: 100%;
        font-size: 1.5rem;
        padding: 8px;
        max-width: auto;
        width: auto;
    }
    
    button {
        max-width: 96px;
        font-size: 18px;
        padding: 8px;
        border: 0;
        background: #ECB525;
        color: white;
        font-weight: normal;
    }
`

const CloseButton = styled.a`
    position: absolute;
    top: -16px;
    right: -16px;
    font-size: 32px;
    color: black;
    cursor: default;
    background: white;
    border-radius: 50%;
    padding: 4px 9px;
    line-height: 1;
`

const SponsorButton = styled.a`
    font-size: 18px;
    padding: 8px;
    border: 0px;
    background: rgb(236, 181, 37);
    color: white;
    font-weight: normal;
    text-align: center;
    margin: 8px 0;
    text-decoration: none;
`

const DisabledButton = styled.a`
    font-size: 18px;
    padding: 8px;
    border: 0px;
    background: rgb(171 171 171);
    color: white;
    font-weight: normal;
    text-align: center;
    margin: 8px 0;
    text-decoration: none;
`